*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	background-color: var(--white);
	/* font-family: "Maven Pro", sans-serif; */
	font-family: "Comfortaa", sans-serif;
	/* font-family: "Cute Font", sans-serif; */
	/* font-family: "Bebas Neue", sans-serif; */
	/* font-family: "Major Mono Display", monospace; */
}
.card .card-title, .card .card-text {
    text-decoration: none;
}

.card {
    transition: 0.25s ease;
    height: 100%;
}
.card:hover {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.3);
}

.card-img-top {
    height: 75%;
}

.card-title.h5 {
    font-size: 1.5rem;
    font-weight: 700;
}

.card .card-img-top {
    object-fit: cover;
}
