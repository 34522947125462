/* CSS Custom Properties */
:root {
	/* CSS HEX */
	--dark-blue: #05668dff;
	--light-blue: #427aa1ff;
	--white: #ebf2faff;
	--light-green: #a5be00ff;
	--dark-green: #679436ff;

	/* CSS HSL */
	--lapis-lazuli: hsla(197, 93%, 29%, 1);
	--ucla-blue: hsla(205, 42%, 45%, 1);
	--alice-blue: hsla(212, 60%, 95%, 1);
	--asparagus: hsla(89, 47%, 40%, 1);
	--apple-green: hsla(68, 100%, 37%, 1);
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Fredoka", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
a {
	text-decoration: none !important;
}
