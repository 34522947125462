.brand-image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.brand-wrapper {
	font-family: "Major Mono Display", monospace;
}

.navbar-brand {
	font-size: 1.5rem;
	font-weight: bold;
}

.navbar {
	background-color: var(--dark-green);
}
.offcanvas-body {
	display: flex;
	justify-content: flex-end;
}

.nav-link {
	color: var(--white);
	text-decoration: none;
}

.nav-link:hover {
	color: var(--light-green);
	text-decoration: none;
}

/* .nav-link:active {
	color: var(--light-green);
	text-decoration: none;
} */

.navbar-brand:hover {
	color: var(--light-green);
}

.navbar-nav {
	gap: 1em;
}

.navbar-toggler {
	width: auto;
	box-shadow: none;
	padding: auto;
	border: 0;
	right: 0;
}
.button {
	outline: none;
}

@media (min-width: 576px) {
    .nav-link {
        padding: 0 !important;
    }
}

@media (max-width: 575.8px) {
    .navbar {
		margin: auto;
    }

    .nav-link {
        font-size: 2rem;
        margin: 20px;
		color: var(--dark-blue);
    }
	.offcanvas-body {
		display: flex;
		justify-content: center;
	}

	.navbar-brand {
		font-size: 1rem;
		font-weight: bold;
	}
}
