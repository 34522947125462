.projects-title {
	display: flex;
	justify-content: center;
	font-size: 2.5rem;
	margin: 1rem;
	padding: 1rem;
	text-decoration: underline var(--dark-blue);
}


.projects-container {
	display: grid;
	grid-template-columns:  1fr;
	grid-template-rows: auto;
	gap: 2rem;
	margin: 2rem auto;
}

.projects-card {
	margin: auto;
	width: 75%;
}