footer {
	background-color: var(--dark-blue);
	color: var(--white);
}
footer .container-fluid {
	margin: 0 auto;
	padding-top: 1em;
    padding-bottom: 0em;
    display: flex;
    justify-content: space-between;
}
footer .socials {
	display: flex;
	gap: 10px;
}
footer .social-icon {
    color: var(--white);
    height: 24px;
    width: 24px;
}
footer .social-icon:hover {
    color: var(--light-blue);
}
.footer-links {
    color: var(--white);
} 
.footer-links:hover {
    color: var(--light-blue);
}

@media (max-width: 576px){
    footer .container-fluid {
        flex-direction: column-reverse;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 1em 0em 1em 0em;
    }

    .footer-link {
        font-size: 1rem;
        padding: 0 10px;
    }
}